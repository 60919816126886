import gql from 'graphql-tag';
import { inviteListResponseFragment } from './fragments';

export const invites = gql`
  query invites($filters: InviteQueryInput!) {
    invites(filters: $filters) {
      collection {
        id
        name
        start
        end
        isFreeAccess
        accessProfile {
          id
          name
          description
        }
        host {
          id
          name
          email
        }
        condominium {
          id
          name
        }
        inviteds {
          id
          name
          accessStatus
        }
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
`;

export const expectedGuests = gql`
  query expectedGuests($condominiumId: Int!, $filters: QueryInput!) {
    expectedGuests(condominiumId: $condominiumId, filters: $filters) {
      collection {
        unityName: unityname
        unitySerializedParams: unityserializedparams
        unityGroupSerializedParams: unitygroupserializedparams
        unityGroupName: unitygroupname
        hostId: hostid
        hostName: hostname
        hostEmail: hostemail
        hostPhone: hostphone
        personContextParamsCallPriority: personcontextparamscallpriority
        personContextParamsPrefixPabx: personcontextparamsprefixpabx
        personContextParamsObservation: personcontextparamsobservation
        inviteId: inviteid
        startDate: startdate
        endDate: enddate
        inviteStatus: invitestatus
        guestParams: guestparams
        guestName: guestname
        guestId: guestid
        guestPhones: guestphones
        personDocuments: persondocuments
        additionalContacts: additionalcontacts
        personContextCreationDateHost: personcontextcreationdatehost
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
`;

export const inviteById = gql`
  query invite($inviteId: Int!) {
    invite(inviteId: $inviteId) {
      id
      name
      start
      end
      isFreeAccess
      accessProfiles {
        id
        name
        description
      }
      host {
        id
        name
        email
      }
      condominium {
        id
        name
      }
      unityGroup {
        name
      }
      unity {
        name
      }
    }
  }
`;

export const guestByInvite = gql`
  query guests($inviteId: Int!, $filters: GuestQueryInput!) {
    guests(inviteId: $inviteId, filters: $filters) {
      collection {
        id
        name
        phoneNumber
        email
        accessStatus
        smsStatus
        syncStatus
        excludedDate
        guestParams {
          documentType
          document
        }
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
`;

export const inviteUpdate = gql`
  mutation($inviteId: Int!, $expectedGuests: ExpectedGuestsByDwellerInput) {
    inviteUpdate(inviteId: $inviteId, expectedGuests: $expectedGuests) {
      name
      start
      end
      isFreeAccess
      id
      creationDate
      createPersonInvite {
        name
      }
      inviteds {
        id
        name
        documents {
          name
          value
          id
        }
      }
    }
  }
`;

export const invitesPaginated = gql`
  query invitesPaginated($filters: InviteQueryInput!) {
    invitesPaginated(filters: $filters) {
      ...inviteListResponseParts
    }
  }
  ${inviteListResponseFragment}
`;
