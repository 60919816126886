import styled from 'styled-components';

export const Nav = styled.div`
  width: 100%;
  height: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background: ${props => props.theme.colors.secondary50};
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 8px;
  box-sizing: border-box;
  gap: 8px;
`;

export const TabTitle = styled.span`
  font-family: ${({ fontFamilyTab }) => fontFamilyTab ?? 'Nunito Sans'};
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: auto;

  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.blackShades(0.87)};

  transition: color 0.15s ease-in;
`;

export const NavLink = styled.button`
  all: unset;

  cursor: pointer;
  font-family: ${({ fontFamilyTab }) => fontFamilyTab ?? 'Nunito Sans'};
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  width: ${props => `calc(${100 / props.tabItemsQuantity}%)`};
  height: 32px;
  padding: 6px;

  background-color: ${({ active, colorActiveTab }) =>
    active ? colorActiveTab : 'transparent'};

  svg {
    color: ${({ active, theme }) =>
      active ? theme.colors.white : theme.colors.blackShades(0.87)};
    width: max-content;
    height: 100%;
    transition: color 0.15s ease-in;
  }

  transition: background-color 0.15s ease-in;
`;
