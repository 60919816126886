import {
  useCallback,
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import propTypes from 'prop-types';
import { MdOutlineClose, MdOutlineExpandLess } from 'react-icons/md';
import { useToggleData, useOutsideClick } from '@kiper/hooks';
import Divider from '../Divider';
import Spinner from '../Spinner';
import Text from '../Text';
import * as S from './styles';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const SearchGroupSelect = ({
  name,
  icon: Icon,
  onSearch,
  onClear,
  value,
  selectValue,
  options,
  loadingOptions,
  showEmptyState,
  emptyState,
  emptyStateDwellersText,
  loadingStateText,
  optionsTitle,
  onSelect,
  secondColumnTitle,
  error,
  ...props
}) => {
  const selectRef = useRef();
  const [optionSelected, setOptionSelected] = useState(null);
  const [maxHeightDropDown, setMaxHeightDropDown] = useState(null);
  const [openDropDown, setOpenDropDown] = useState(true);
  const [openedRow, toggleOpenedRow] = useToggleData({});

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    const height = selectRef?.current?.getBoundingClientRect().height;
    const bottom = selectRef?.current?.getBoundingClientRect().bottom;
    const overflowDiff = bottom - windowDimensions.height;
    const newMaxHeight = height - overflowDiff;

    if (newMaxHeight) setMaxHeightDropDown(`${newMaxHeight}px`);
  }, [selectRef?.current, value, windowDimensions]);

  const mainBoxRef = useOutsideClick(() => setOpenDropDown(false));

  useEffect(() => {
    if (selectValue) {
      onSelect(selectValue);
      onSearch(selectValue.description);
      setOptionSelected(selectValue.description);
    }
  }, []);

  const handleSearch = useCallback(
    event => {
      if (optionSelected) setOptionSelected(null);
      onSearch(event.target.value);
      setOpenDropDown(true);
    },
    [onSearch],
  );

  const handleSelect = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedValue = option.description;
    setOptionSelected(selectedValue);
    onSelect(option);
  };

  const handleCleanSearch = () => {
    onClear();
    setOptionSelected(null);
    onSearch('');
  };

  const handleSeeMore = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    toggleOpenedRow(option.id);
  };

  const handleFocusSearchInput = () => {
    if (optionSelected || value) setOpenDropDown(true);
  };

  const showOptions = !loadingOptions && !!options.length && !optionSelected;

  const showDropDownOptions =
    openDropDown && (value || loadingOptions || showEmptyState || showOptions);

  return (
    <S.Wrapper ref={mainBoxRef}>
      <S.InputGroup error={error}>
        {Icon && (
          <S.InputIcon onClick={e => e.stopPropagation()}>{Icon}</S.InputIcon>
        )}
        <S.WrapperSearchInput>
          <S.Input
            {...props}
            autoComplete="off"
            aria-label={name}
            name={name}
            id={name}
            onChange={handleSearch}
            value={optionSelected ?? value}
            onFocus={handleFocusSearchInput}
            onClick={handleFocusSearchInput}
            autoFocus
          />
        </S.WrapperSearchInput>
        {value && (
          <S.IconButton onClick={handleCleanSearch}>
            <MdOutlineClose />
          </S.IconButton>
        )}
        <S.InputIcon>
          <S.ExpandMoreIcon />
        </S.InputIcon>
      </S.InputGroup>
      {showDropDownOptions && (
        <S.WrapperSelectOptions ref={selectRef} maxHeight={maxHeightDropDown}>
          {loadingOptions && (
            <S.EmptyOptionsContainer>
              <Spinner size={24} />
              <Text>{loadingStateText}</Text>
            </S.EmptyOptionsContainer>
          )}

          {showEmptyState && (
            <S.EmptyOptionsContainer>{emptyState}</S.EmptyOptionsContainer>
          )}

          {showOptions && (
            <>
              <S.GroupSearchTitle>{optionsTitle}</S.GroupSearchTitle>
              <Divider />
              {options?.map(option => (
                <Fragment key={option.id}>
                  <S.OptionsList>
                    <S.ListItem onClick={e => handleSelect(e, option)}>
                      <S.GroupOption>
                        <S.GroupOptionLabel>
                          {option.description}
                        </S.GroupOptionLabel>
                        <S.GroupOptionValue>
                          {option?.unityGroup?.description}
                        </S.GroupOptionValue>
                      </S.GroupOption>
                      <S.GroupOption>
                        <S.GroupOptionLabel>
                          {!!option?.dwellers?.length && secondColumnTitle}
                        </S.GroupOptionLabel>
                        <S.ChipWrapper>
                          <S.ChipsContainer>
                            {!option?.dwellers?.length ? (
                              <S.EmptyDwellersText>
                                {emptyStateDwellersText}
                              </S.EmptyDwellersText>
                            ) : (
                              <>
                                {option?.dwellers
                                  ?.slice(
                                    0,
                                    openedRow[option.id] ? undefined : 2,
                                  )
                                  .map(dweller => (
                                    <S.GroupOptionValuesChip
                                      key={dweller.id}
                                      title={dweller.name}
                                    >
                                      {dweller.name}
                                    </S.GroupOptionValuesChip>
                                  ))}
                              </>
                            )}
                          </S.ChipsContainer>
                          {option?.dwellers?.length > 2 && (
                            <S.ChipButtonMoreItems
                              onClick={e => handleSeeMore(e, option)}
                              seeMore={+openedRow[option.id]}
                            >
                              {!openedRow[option.id] ? (
                                `+${option.dwellers.length - 2}`
                              ) : (
                                <MdOutlineExpandLess size={16} />
                              )}
                            </S.ChipButtonMoreItems>
                          )}
                        </S.ChipWrapper>
                      </S.GroupOption>
                    </S.ListItem>
                  </S.OptionsList>
                </Fragment>
              ))}
            </>
          )}
        </S.WrapperSelectOptions>
      )}
    </S.Wrapper>
  );
};

export default SearchGroupSelect;

SearchGroupSelect.propTypes = {
  onSearch: propTypes.func.isRequired,
  onSelect: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  value: propTypes.string,
  icon: propTypes.element,
  loadingOptions: propTypes.bool.isRequired,
  options: propTypes.array,
  loadingStateText: propTypes.string.isRequired,
  showEmptyState: propTypes.bool.isRequired,
  emptyState: propTypes.element.isRequired,
  secondColumnTitle: propTypes.string,
  onClear: propTypes.func.isRequired,
  optionsTitle: propTypes.string.isRequired,
  selectValue: propTypes.any,
  error: propTypes.bool,
  emptyStateDwellersText: propTypes.string.isRequired,
};

SearchGroupSelect.defaultProps = {
  icon: <></>,
  value: '',
  options: [],
  secondColumnTitle: '',
  selectValue: null,
  error: false,
};
