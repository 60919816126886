import gql from 'graphql-tag';
import {
  deviceFragment,
  placeFragment,
  deviceRuleFragment,
  condominiumRuleTypeFragment,
  condominiumAccessHoursFragment,
  updateHistoryNoteFragment,
  createHistoryNoteFragment,
} from './fragments';

// AccessProfile
export const deleteAccessProfile = gql`
  mutation deleteAccessProfile($accessProfileId: Int!) {
    removeAccessProfile(accessProfileId: $accessProfileId)
  }
`;

// Address
export const updateAddress = gql`
  mutation personUpdateAddress(
    $address: CondominiumAddressInput!
    $personId: Int!
  ) {
    personUpdateAddress(address: $address, personId: $personId) {
      id
      streetName
      zipNumber
      addressNumber
      addressTypeId
      isPrincipal
      district {
        value
        label
      }
      city {
        value
        label
      }
      region {
        value
        label
      }
      country {
        value
        label
      }
    }
  }
`;

// DeviceList
export const removeDevice = gql`
  mutation deviceRemove($placeId: Int!, $deviceId: Int!, $desync: Boolean) {
    deviceRemove(placeId: $placeId, deviceId: $deviceId, desync: $desync)
  }
`;

// GeneralData
export const updateCondominium = gql`
  mutation condominiumUpdate($condominium: CondominiumPut!) {
    condominiumUpdate(condominium: $condominium) {
      id
      name
      unityType {
        id
        label
      }
      unityGroupType {
        id
        label
      }
      params {
        name
        value
      }
    }
  }
`;

// Parameters
export const removePlace = gql`
  mutation placeRemove($placeId: Int!) {
    placeRemove(placeId: $placeId)
  }
`;

// Modal/AddPlace
export const createPlace = gql`
  mutation placeCreate($place: PlaceInput!) {
    placeCreate(place: $place) {
      ...placeParts
    }
  }
  ${placeFragment}
`;

export const updatePlace = gql`
  mutation placeUpdate($place: PlaceInput!) {
    placeUpdate(place: $place) {
      id
      name
      placeId
      placeTypeLabelId
      comments
      placeTypeId
      placeType {
        id
        name
      }
      serializedParams
    }
  }
`;

// Modal/PutDevice
export const putDevice = gql`
  mutation devicePut(
    $placeId: Int!
    $device: DeviceInput!
    $includes: Includes
  ) {
    devicePut(placeId: $placeId, device: $device, includes: $includes) {
      ...deviceParts
    }
  }
  ${deviceFragment}
`;

// Modal/insertDevice
export const insertDevice = gql`
  mutation deviceInsert(
    $placeId: Int!
    $device: DeviceInput!
    $includes: Includes
  ) {
    deviceInsert(placeId: $placeId, device: $device, includes: $includes) {
      ...deviceParts
    }
  }
  ${deviceFragment}
`;

// Components/PutPersonAccessProfile
export const putAccessProfile = gql`
  mutation putPersonAccessProfile($accessProfile: AccessProfileInput!) {
    putAccessProfile(accessProfile: $accessProfile) {
      id
      name
      personId
      places {
        id
        name
      }
    }
  }
`;

export const putVehicleControlSetting = gql`
  mutation putVehicleControlSetting(
    $vehicleControlSettings: [VehicleControlSettingInput]
    $accessProfileId: Int
  ) {
    putVehicleControlSetting(
      vehicleControlSettings: $vehicleControlSettings
      accessProfileId: $accessProfileId
    ) {
      set_rf {
        button1
        button2
        button3
        button4
      }
    }
  }
`;

export const removePlaceTypeLabel = gql`
  mutation placeTypeLabelRemove($id: Int!) {
    placeTypeLabelRemove(id: $id)
  }
`;

export const personContextParamsCreate = gql`
  mutation personContextParamsCreate($data: PersonContextParamsInput) {
    personContextParamsCreate(data: $data) {
      id
      name
      value
      lastUpdate
      creationDate
    }
  }
`;

export const personContextParamsDelete = gql`
  mutation personContextParamsDelete($id: Int!) {
    personContextParamsDelete(id: $id)
  }
`;

export const personContextParamsUpdate = gql`
  mutation personContextParamsUpdate($id: ID, $data: PersonContextParamsInput) {
    personContextParamsUpdate(id: $id, data: $data) {
      id
      name
      value
    }
  }
`;

export const interlockedPlaceCreate = gql`
  mutation interlockedPlaceCreate($interlock: InterlockedPlaceInput!) {
    interlockedPlaceCreate(interlock: $interlock) {
      id
      name
      creationDate
      places {
        place {
          ...placeParts
        }
      }
    }
  }
  ${placeFragment}
`;

export const interlockedPlaceUpdate = gql`
  mutation interlockedPlaceUpdate($interlock: InterlockedPlaceInput!) {
    interlockedPlaceUpdate(interlock: $interlock) {
      id
      name
      creationDate
      places {
        place {
          ...placeParts
        }
      }
    }
  }
  ${placeFragment}
`;

export const interlockedPlaceDelete = gql`
  mutation interlockedPlaceDelete($interlockId: Int!) {
    interlockedPlaceDelete(interlockId: $interlockId)
  }
`;

export const deviceRuleInsert = gql`
  mutation deviceRuleInsert($deviceId: Int!, $deviceRule: DeviceRuleInput!) {
    deviceRuleInsert(deviceId: $deviceId, deviceRule: $deviceRule) {
      ...deviceRuleParts
    }
  }
  ${deviceRuleFragment}
`;

export const deviceRulePut = gql`
  mutation deviceRulePut($deviceId: Int!, $deviceRule: DeviceRuleInput!) {
    deviceRulePut(deviceId: $deviceId, deviceRule: $deviceRule) {
      ...deviceRuleParts
    }
  }
  ${deviceRuleFragment}
`;

export const deviceRuleRemove = gql`
  mutation deviceRuleRemove($ruleId: Int!, $deviceId: Int!) {
    deviceRuleRemove(ruleId: $ruleId, deviceId: $deviceId)
  }
`;

export const enablePatrol = gql`
  mutation activatePatrol($condominiumId: Int!, $active: Boolean) {
    activatePatrol(condominiumId: $condominiumId, active: $active)
  }
`;

export const createPatrolSetting = gql`
  mutation createPatrolSetting($data: PatrolSettingInput) {
    createPatrolSetting(data: $data) {
      id
      condominiumId
      days
      frequency
      start {
        hours
        minutes
      }
      end {
        hours
        minutes
      }
      startTime
      endTime
      creationDate
    }
  }
`;

export const updatePatrolSetting = gql`
  mutation updatePatrolSetting($id: ID, $data: PatrolSettingInput) {
    updatePatrolSetting(id: $id, data: $data) {
      id
      condominiumId
      days
      frequency
      start {
        hours
        minutes
      }
      end {
        hours
        minutes
      }
      startTime
      endTime
      creationDate
    }
  }
`;

export const removePatrolSetting = gql`
  mutation removePatrolSetting($id: ID) {
    removePatrolSetting(id: $id)
  }
`;

export const reorderPlaces = gql`
  mutation reorderPlaces($places: [CondominiumPlaceOrderInput]) {
    reorderPlaces(places: $places) {
      id
      treeNodeId
      placeTypeId
      name
      order
      comments
    }
  }
`;

export const updateFirmware = gql`
  mutation updateFirmware($serial: String, $firmwareId: String) {
    updateFirmware(serial: $serial, firmwareId: $firmwareId)
  }
`;

export const downgradeFirmware = gql`
  mutation downgradeFirmware($serial: String, $firmwareId: String) {
    downgradeFirmware(serial: $serial, firmwareId: $firmwareId)
  }
`;

export const createCondominiumRule = gql`
  mutation condominiumRule(
    $condominiumId: ID!
    $condominiumRule: CondominiumRuleInput!
  ) {
    condominiumRule(
      condominiumId: $condominiumId
      condominiumRule: $condominiumRule
    ) {
      ...condominiumRuleTypeParts
    }
  }
  ${condominiumRuleTypeFragment}
`;

export const createCondominiumDefaultRules = gql`
  mutation condominiumDefaultRules($condominiumId: ID!) {
    condominiumDefaultRules(condominiumId: $condominiumId) {
      ...condominiumRuleTypeParts
    }
  }
  ${condominiumRuleTypeFragment}
`;

export const updateCondominiumRule = gql`
  mutation updateCondominiumRule($condominiumRule: CondominiumRuleInput!) {
    updateCondominiumRule(condominiumRule: $condominiumRule) {
      ...condominiumRuleTypeParts
    }
  }
  ${condominiumRuleTypeFragment}
`;

export const removeCondominiumRule = gql`
  mutation removeCondominiumRule($condominiumRuleId: ID!) {
    removeCondominiumRule(condominiumRuleId: $condominiumRuleId)
  }
`;

export const changeAccessProfile = gql`
  mutation changeAccessProfile(
    $changeAccessProfileObject: AccessProfileChangeInput!
  ) {
    changeAccessProfile(changeAccessProfileObject: $changeAccessProfileObject)
  }
`;

export const placeCamerasSetup = gql`
  mutation placeCamerasSetup(
    $placeId: Int!
    $placeCamerasSetup: [PlaceCamerasSetupInput]!
  ) {
    placeCamerasSetup(placeId: $placeId, placeCamerasSetup: $placeCamerasSetup)
  }
`;

export const condominiumActivateMonitoring = gql`
  mutation condominiumActivateMonitoring($condominiumContextId: ID!) {
    condominiumActivateMonitoring(condominiumContextId: $condominiumContextId)
  }
`;

export const enableEventPushNotification = gql`
  mutation enableEventPushNotification(
    $condominiumContextId: String!
    $eventId: String!
    $enable: String!
  ) {
    enableEventPushNotification(
      condominiumContextId: $condominiumContextId
      eventId: $eventId
      enable: $enable
    ) {
      personContextId
      eventType
      name
    }
  }
`;

export const placeUpdateIgnoredTriage = gql`
  mutation placeUpdateIgnoredTriage(
    $placeId: Int!
    $ignoredTriage: ignoredTriageInput!
  ) {
    placeUpdateIgnoredTriage(placeId: $placeId, ignoredTriage: $ignoredTriage) {
      id
      name
      comments
      placeTypeId
      serializedParams
    }
  }
`;

export const createCondominiumAccessHours = gql`
  mutation insertCondominiumAccessHours(
    $condominiumPersonContextId: Int!
    $body: CondominiumAccessHoursInput!
  ) {
    insertCondominiumAccessHours(
      condominiumPersonContextId: $condominiumPersonContextId
      body: $body
    ) {
      ...condominiumAccessHoursParts
    }
  }
  ${condominiumAccessHoursFragment}
`;

export const createHistoryNote = gql`
  mutation createHistoryNote(
    $condominiumPersonContextId: ID
    $data: HistoryNoteDataInput
  ) {
    createHistoryNote(
      condominiumPersonContextId: $condominiumPersonContextId
      data: $data
    ) {
      ...createHistoryNoteParts
    }
  }
  ${createHistoryNoteFragment}
`;

export const updateHistoryNote = gql`
  mutation updateHistoryNote(
    $historyNoteId: ID
    $data: UpdateHistoryNoteDataInput
  ) {
    updateHistoryNote(historyNoteId: $historyNoteId, data: $data) {
      ...updateHistoryNoteParts
    }
  }
  ${updateHistoryNoteFragment}
`;
