import gql from 'graphql-tag';

export const persons = gql`
  query persons($filters: PersonQueryInput!) {
    persons(filters: $filters) {
      collection {
        personId
        personContextId
        name
        email
        profileName
        creationDate
        profileId
        profile {
          id
          fieldName
        }
      }
      pagination {
        pageSize
        totalResults
        totalPages
        page
        previous
        next
      }
    }
  }
`;

export const personContextDelete = gql`
  mutation personContextDelete($personContextId: Int!) {
    personContextDelete(personContextId: $personContextId)
  }
`;

export const personContext = gql`
  query personContext($id: ID!) {
    personContext(personContextId: $id) {
      profileId
      treeNodeId
      person {
        id
        name
        email
        phone
        profileId
      }
    }
  }
`;

export const person = gql`
  query person($sourceTreeNodeId: Int!, $personId: Int!) {
    person(sourceTreeNodeId: $sourceTreeNodeId, personId: $personId) {
      id
      name
      phone
      email
      treeNodeId
      personId
      personContextId
    }
  }
`;

export const personSelect = gql`
  query personSelect($filters: PersonSelectQueryInput!) {
    personSelect(filters: $filters) {
      value
      label
      contextId
    }
  }
`;

export const checkPersonExist = gql`
  query checkPersonExist($email: String!, $sourceTreeNodeId: Int) {
    checkPersonExist(email: $email, sourceTreeNodeId: $sourceTreeNodeId) {
      id
      name
      phone
      email
      treeNodeId
      personId
      personContextId
    }
  }
`;
