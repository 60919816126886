import styled, { css } from 'styled-components';
import { Label } from 'reactstrap';

export default styled(Label)`
  letter-spacing: 0.5px;
  line-height: 1.5;

  ${props => css`
    cursor: ${props.isCheckboxLabel ? 'pointer' : 'inset'};
    color: ${props.info
      ? props.theme.colors.mediumBlack
      : props.theme.colors.highBlack};
    width: ${props.block ? '100%' : 'initial'};
    margin-bottom: ${props.$withoutMarginBottom ? 0 : '5px'};
    text-align: ${props.center && 'center'};
    font-weight: ${props.weight ? props.size : 500};
    font-size: ${props.size ? props.size : '14px'};
    line-height: 16px;

    ${props.bolder &&
      css`
        font-weight: bolder;
        font-size: 16px;
      `}
  `}
`;
